import { SiHtml5 } from "react-icons/si";
import { FaCss3Alt,FaBootstrap,FaNode,FaReact,FaCopyright,FaGithub } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { SiExpress,SiMongodb } from "react-icons/si";
import { RiTailwindCssLine } from "react-icons/ri";
import { RiNextjsFill } from "react-icons/ri";
export const TechstackList = [
    {
        _id:1,
        name:"HTML",
        icon: SiHtml5 
    },
    {
        _id:2,
        name:"CSS",
        icon: FaCss3Alt
    },
    {
        _id:3,
        name:"JAVASCRIPT",
        icon: IoLogoJavascript
    },
    {
        _id:4,
        name:"BOOTSTRAP",
        icon: FaBootstrap
    },
    {
        _id:5,
        name:"NODE.JS",
        icon: FaNode 
    },
    {
        _id:6,
        name:"EXPRESS.JS",
        icon: SiExpress 
    },
    {
        _id:7,
        name:"TAILWIND.CSS",
        icon: RiTailwindCssLine 
    },
    {
        _id:8,
        name:"REACT.JS",
        icon: FaReact
    },
    {
        _id:9,
        name:"MANGODB",
        icon: SiMongodb 
    },
    {
        _id:10,
        name:"NEXT.JS",
        icon: RiNextjsFill
    },
    {
        _id:11,
        name:"C LANGUAGE",
        icon: FaCopyright
    },
    {
        _id:12,
        name:" GIT /GITHUB",
        icon: FaGithub
    },
];